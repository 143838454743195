<script>
export default {
  name: "CopyKeywords",
  props: {
    campaignId: Number
  }
}
</script>

<template>
  <v-row>
    <v-col cols="12" sm="5" class="pl-5 pt-5 title">Copy Keywords to</v-col>
    <v-col cols="12" sm="4" class="pt-0 pt-0">
      <v-text-field v-model="toId" label="ID" :rules="[checkCopyId]"></v-text-field>
    </v-col>
    <v-col cols="12" sm="3">
      <v-btn color="primary" :disabled="!isAllowCopyKeywords" @click="onCopy">Copy
        <v-icon class="pl-2" dark>mdi-send</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<style scoped>

</style>